import * as React from "react";
import GoogleMap from "google-map-react";
import axios from "axios";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import "./map.scss";

export function Map(props) {
  const [locations, setLocations] = React.useState([]);
  const [currentState, setCurrentState] = React.useState("NO_DATA");

  React.useEffect(() => {
    setCurrentState("LOADING");
    setLocations([]);

    axios
      .get(`https://randomuser.me/api/?results=350&nat=us`)
      .then(function (response) {
        // Extract location coordinates from the API response
        const markers =
          response?.data?.results?.map(function (user) {
            return {
              lat: parseFloat(user.location.coordinates.latitude),
              lng: parseFloat(user.location.coordinates.longitude),
              title: user.name.first + " " + user.name.last, // Set the title
            };
          }) || [];

        if (markers?.length) {
          setLocations(markers);
        } else {
          setCurrentState("NO_DATA");
          setLocations([]);
        }
      })
      .catch(function (error) {
        setLocations([]);
        setCurrentState("DATA");

        console.log(error);
      });
  }, [])

  const handleApiLoaded = (map, maps) => {
    const markers = [];
    const infoWindows = [];

    locations.forEach((location) => {
      let newMarker = new maps.Marker({
        position: {
          lat: location.lat,
          lng: location.lng,
        },
        map,
        title: location.title,
      });

      let newInfoWindow = new maps.InfoWindow({
        content: location.title,
      });

      newMarker.addListener("click", function () {
        // Close all other info windows
        infoWindows.forEach(function (otherInfoWindow) {
          if (newInfoWindow !== otherInfoWindow) {
            otherInfoWindow.close();
          }
        });
        newInfoWindow.open(map, newMarker);
      });

      infoWindows.push(newInfoWindow);
      markers.push(newMarker);
    });

    new MarkerClusterer({ markers, map });
  };

  return (
    <>
      <div className="react-map-block">
        {
          !!locations?.length ? <GoogleMap
            defaultCenter={{ lat: -31.56391, lng: 147.154312 }}
            defaultZoom={0}
            bootstrapURLKeys={{
              key: "AIzaSyAtN8PAcGjUDSRs5r6zuLoQatkT-UeqXSo",
            }}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => {
              handleApiLoaded(map, maps);
            }}
          /> : currentState === "NO_DATA" ? "No data to display yet" : "Loading..."
        }
      </div>
    </>
  );
}
